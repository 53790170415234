import Layout from '@eazyportal/layout-ui-lib';

import React from 'react';

class App extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Layout.App />
      </React.Fragment>
    );
  }
}

export default App;
