import Auth from '@eazyportal/auth-ui-lib';
import DocumentStore from '@eazyportal/document-store-ui-lib';

import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App';

import '@eazyportal/core-ui-lib/dist/index.css'
import '@eazyportal/layout-ui-lib/dist/index.css'

const rootElement = document.getElementById('share-it-root')

ReactDOM.createRoot(rootElement)
  .render(<App />)

const observer = new MutationObserver(async (mutations) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((node) => {
      if (node.id === 'auth-root') {
        require('@eazyportal/auth-ui-lib/dist/index.css')
        ReactDOM.createRoot(document.getElementById('auth-root'))
          .render(<Auth.App asWidget={true} />);

        // observer.disconnect();
      } else if (node.id === 'document-store-root') {
        require('@eazyportal/document-store-ui-lib/dist/index.css')

        ReactDOM.createRoot(document.getElementById('document-store-root'))
          .render(<DocumentStore.App asWidget={true} />);

        // observer.disconnect();
      }
    });
  });
});

observer.observe(rootElement, { childList: true, subtree: true });
